import files from '@/config/files';
import { File, FileBucket } from '@prisma/client';

export function urlForFile(file: File) {
  return `${files.host}/${fullBucketName(file.bucket)}/${file.id}`;
}

export function fullBucketName(bucket: FileBucket): string {
  return `openedco-${bucket.toLowerCase()}`;
}
