import env from './environment';

const files = {
  region: process.env.AWS_REGION!,
  accessKeyId: process.env.AWS_ACCESS_KEY_ID!,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY!,
  get forcePathStyle() {
    return env.isLocal;
  },
  get host() {
    return process.env.FILES_HOST || 'http://files:9000';
  },
};

export default files;
