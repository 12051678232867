'use client';
import { ActionErrorResponse, ActionResult } from '@/lib/action';
import { useAtomValue } from 'jotai';
import { omit } from 'lodash';
import { useRouter } from 'next/navigation';
import {
  createContext,
  useContext,
  forwardRef,
  ForwardedRef,
  useEffect,
  useId,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useFormState, useFormStatus } from 'react-dom';
import { formUploadsAtomFamily } from './file-input';
import { AnimatePresence, motion } from 'framer-motion';

export interface FormProps<T>
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'action'> {
  action: (
    prevState: unknown,
    formData: FormData
  ) => Promise<ActionResult<T> | typeof INITIAL>;
  onSuccess?: string | ((result: T) => void);
  onError?: (error: unknown) => void;
  title?: string;
  description?: string;
  args?: Record<string, string>;
  minDuration?: number;
  preprocess?: (formData: FormData) => Promise<FormData> | FormData;
}

const FormErrorContext = createContext<{
  serverError: ActionErrorResponse | null;
  clientFieldErrors: Record<string, string | undefined>;
  setClientFieldErrors: Dispatch<SetStateAction<Record<string, string>>>;
} | null>(null);

export const INITIAL = Symbol.for('initial');

function isErrorResponse(
  state: ActionResult<unknown>
): state is { error: ActionErrorResponse } {
  return typeof state === 'object' && state !== null && 'error' in state;
}

export default forwardRef(function Form<T>(
  {
    action,
    title,
    description,
    args,
    preprocess,
    onSuccess,
    onError,
    minDuration,
    children,
    ...otherProps
  }: FormProps<T>,
  ref: ForwardedRef<HTMLFormElement>
) {
  minDuration = minDuration ?? 800;
  const router = useRouter();
  const internalId = useId();
  const formId = otherProps.id ?? internalId;
  const uploads = useAtomValue(formUploadsAtomFamily(formId));

  // Track client errors
  const [clientFieldErrors, setClientFieldErrors] = useState<
    Record<string, string>
  >({});

  // Add success/error callbacks to the action
  const actionWithCallbacks = useCallback(
    async (prevState: unknown, formData: FormData) => {
      if (Object.entries(clientFieldErrors).length > 0) {
        return;
      }
      // Wait for all uploads to complete before proceeding
      await Promise.all(uploads);
      if (preprocess) {
        formData = await preprocess(formData);
      }
      const result = await ensureMinDuration(
        action(prevState, formData),
        minDuration
      );
      if (isErrorResponse(result)) {
        onError?.(result.error);
      } else if (typeof result !== 'symbol') {
        if (typeof onSuccess === 'string') {
          router.push(onSuccess);
        } else {
          onSuccess?.(result);
        }
      }
      return result;
    },
    [
      action,
      clientFieldErrors,
      minDuration,
      onError,
      onSuccess,
      preprocess,
      router,
      uploads,
    ]
  );

  // Track form state
  const [state, formAction] = useFormState(actionWithCallbacks, INITIAL);

  // Extract server errors from responses
  const [serverError, setServerError] = useState<ActionResult['error'] | null>(
    null
  );
  useEffect(() => {
    if (state && typeof state === 'object' && 'error' in state) {
      setServerError(state.error);
    }
  }, [state]);

  const descriptionId = useId();

  return (
    <form
      {...otherProps}
      id={formId}
      action={formAction}
      ref={ref}
      title={title}
      aria-describedby={descriptionId}
    >
      <FormErrorContext.Provider
        value={{ serverError, clientFieldErrors, setClientFieldErrors }}
      >
        <FormError />
        {args &&
          Object.entries(args).map(([key, value]) => (
            <input key={key} type="hidden" name={key} value={value} />
          ))}
        {title && (
          <>
            <div className="stack">
              <div className="font-medium">{title}</div>
              {description && (
                <div className="text-muted text-sm" id={descriptionId}>
                  {description}
                </div>
              )}
              <div className="h-2"></div>
              <hr />
            </div>
          </>
        )}
        {children}
      </FormErrorContext.Provider>
    </form>
  );
});

export function FormSubmittingIndicator(props: {
  delay?: number;
  children: React.ReactNode;
}) {
  const { pending } = useFormStatus();
  return (
    <AnimatePresence>
      {pending ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: props.delay ?? 0 }}
        >
          {props.children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export function useFieldError(name: string) {
  const ctx = useContext(FormErrorContext);
  if (!ctx) throw new Error('useFieldErrors must be used within a Form');
  const { clientFieldErrors, setClientFieldErrors, serverError } = ctx;
  let error =
    clientFieldErrors[name] ??
    (serverError && 'fields' in serverError ? serverError.fields[name] : null);
  if (error && typeof error !== 'string' && 'message' in error) {
    error = error.message;
  }
  const setError = useCallback(
    (error: string | null) =>
      setClientFieldErrors((prev) =>
        error === null ? omit(prev, name) : { ...prev, [name]: error }
      ),
    [name, setClientFieldErrors]
  );
  return [error, setError] as const;
}

function FormError() {
  const ctx = useContext(FormErrorContext);
  if (!ctx) throw new Error('FormError must be used within a Form');
  const { serverError } = ctx;
  const formError = serverError && 'form' in serverError && serverError.form;
  if (!formError) return null;
  return (
    <div className="bg-red-500/80 rounded px-3 py-2 text-sm font-sans overflow-auto whitespace-pre my-2">
      <div className="font-medium">
        Whoops, something's not quite right there:
      </div>
      {formError.message.trim()}
    </div>
  );
}

async function ensureMinDuration<T>(
  promise: Promise<T>,
  minDuration: number
): Promise<T> {
  const [result] = await Promise.all([
    promise,
    new Promise((resolve) => setTimeout(resolve, minDuration)),
  ]);
  return result;
}
