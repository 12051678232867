'use client';

import * as React from 'react';
import { Loader2, LucideIcon } from 'lucide-react';
import { Slot } from '@radix-ui/react-slot';
import Form, { FormProps } from './form';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'mxcn';
import { useFormStatus } from 'react-dom';
import mergeRefs from 'merge-refs';
import { motion } from 'framer-motion';

const buttonVariants = cva(
  [
    'inline-flex gap-2 items-center justify-center whitespace-nowrap rounded-md text-sm transition-colors font-medium',
    '[&_svg]:opacity-70 [&_svg]:hover:opacity-100 [&_svg]:transition',
    'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-focus-500/40',
    'disabled:pointer-events-none disabled:opacity-50',
  ].join(' '),
  {
    variants: {
      variant: {
        none: '',
        default:
          'bg-contrast-700 text-contrast-0 shadow hover:bg-contrast-900 hover:bg-opacity-90 focus-visible:text-contrast-0 focus-visible:ring-4 focus-visible:ring-contrast-600',
        primary:
          'bg-brand-700 text-brand-50 shadow hover:bg-brand-900 hover:bg-opacity-90',
        destructive:
          'bg-red-500 text-gray-50 shadow-sm hover:bg-red-500/90 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900/90',
        secondaryDestructive:
          'bg-contrast-0 text-red-700 shadow-sm hover:bg-red-100 hover:bg-opacity-80 border border-red-200',
        outline:
          'border border-contrast-200 bg-white shadow-sm hover:bg-contrast-100 hover:text-contrast-900',
        secondary:
          'bg-contrast-0 text-contrast-900 shadow-sm hover:bg-contrast-100 hover:bg-opacity-80 border border-contrast-300',
        ghost: 'hover:bg-contrast-100 hover:text-contrast-900',
        link: 'text-contrast-900 underline-offset-4 hover:underline',
      },
      size: {
        none: '',
        default: 'h-9 px-4 py-2',
        xs: 'h-6 rounded-md px-1 text-xs',
        sm: 'h-7 rounded-md px-2 text-sm',
        lg: 'h-10 rounded-md px-4 text-base',
        xl: 'h-14 rounded-md px-6',
        icon: 'p-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  action?: FormProps<unknown>['action'];
  args?: Record<string, string>;
  onSuccess?: () => void;
  as?: string;
  asChild?: boolean;
  icon?: LucideIcon;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      action,
      args,
      onSuccess,
      children,
      className,
      variant,
      size,
      asChild = false,
      as,
      icon: Icon,
      ...props
    },
    ref
  ) => {
    const Comp = as ?? (asChild ? Slot : 'button');
    const { pending } = useFormStatus();

    const busy = pending && props.type === 'submit';
    props.disabled = props.disabled ?? busy;

    const dimensionsRef = React.useRef<HTMLFormElement | HTMLButtonElement>(
      null
    );

    if (busy) {
      props.style ??= {};
      const dimensions = dimensionsRef.current?.getBoundingClientRect();
      if (dimensions) {
        props.style.width = dimensions.width;
        props.style.height = dimensions.height;
      }

      children = (
        <motion.div
          className="flex items-center justify-center h-full cursor-wait"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Loader2 className="animate-spin h-3/4" />
        </motion.div>
      );
    }

    if (action) {
      return (
        <Form
          action={action}
          className="contents"
          onSuccess={onSuccess}
          ref={dimensionsRef as React.RefObject<HTMLFormElement>}
        >
          {args &&
            Object.entries(args).map(([name, value]) => (
              <input key={name} type="hidden" name={name} value={value} />
            ))}
          <Button
            variant={variant}
            size={size}
            className={className}
            {...props}
            type="submit"
            ref={ref}
          >
            {children}
          </Button>
        </Form>
      );
    }

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={mergeRefs(ref, dimensionsRef)}
        draggable="false"
        {...props}
      >
        {children}
        {Icon && <Icon className="ml-[0.5em] w-[1.3em] h-[1.3em]" />}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
