'use client';

import { createFileUploadUrl, updateFile } from '@/lib/files.actions';
import { File, FileBucket, FileUploadStatus } from '@prisma/client';
import createId from '@/lib/id';
import { useState, ComponentPropsWithoutRef, ReactNode } from 'react';
import cn from 'mxcn';
import { useFieldError } from './form';
import { AnimatePresence, motion } from 'framer-motion';
import { AlertTriangle, UploadCloud } from 'lucide-react';
import { atomFamily, useAtomCallback } from 'jotai/utils';
import { atom } from 'jotai';
import { without } from 'lodash';
import { Progress } from './progress';
import Image from 'next/image';
import { urlForFile } from '@/lib/files';

// File inputs register themselves with the form so we can wait for all uploads
// to complete before submitting the form.
export const formUploadsAtomFamily = atomFamily((_formId: string) =>
  atom<Promise<unknown>[]>([])
);

/**
 * Handles client-side presigned file uploads seamlessly with a standard form.
 *
 * This component works by handling the actual file upload "out of band" from
 * the form it is a part of. It begins uploading the file as soon as it is
 * selected by the user. The component invokes the server action to create the
 * File record in our database and get the presigned URL (incl. refreshing as
 * needed), and then immediately starts uploading. Once the upload is complete,
 * it injects the resulting file ID into the parent form as a hidden input.
 *
 * So you can use this in a form like a regular input, and on the server, you'll
 * receive the file ID under the `name` you've provided.
 */
export default function FileInput(props: {
  name: string;
  bucket: FileBucket;
  accept?: string;
  onFileChange?: (file: File) => void;
  className?: string;
  defaultValue?: File | null;
  children?: (props: {
    isUploading: boolean;
    progress: number;
    fileRecord: File | null;
  }) => ReactNode;
}) {
  const [id] = useState<string>(createId('file'));
  const [uploaded, setUploaded] = useState(false);
  const [currentUpload, setCurrentUpload] = useState<AbortController | null>(
    null
  );
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileRecord, setFileRecord] = useState<File | null>(
    props.defaultValue ?? null
  );
  const [, setError] = useFieldError(props.name ?? '');

  // Track uploads in the parent form so we can wait for all uploads to complete
  // before submitting the form.
  const addUploadToForm = useAtomCallback(
    (_, set, formId: string, promise: Promise<unknown>) => {
      set(formUploadsAtomFamily(formId), (prev) => [...prev, promise]);
    }
  );
  const removeUploadFromForm = useAtomCallback(
    (_, set, formId: string, promise: Promise<unknown>) => {
      set(formUploadsAtomFamily(formId), (prev) => without(prev, promise));
    }
  );

  // When the user selects a file, cancel any in-progress uploads, and start uploading
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const form = e.currentTarget.form;
    const file = e.currentTarget.files?.[0];
    if (!file) return;
    setUploaded(false);
    setUploadProgress(0);
    setFileRecord(null);

    try {
      const result = await createFileUploadUrl({
        bucket: props.bucket,
        id,
        mimeType: file.type,
      });
      if ('error' in result)
        throw new FileUploadFailedError(
          (result.error &&
            ('fields' in result.error
              ? result.error.fields[props.name]?.message
              : result.error.form.message)) ??
            'Whoops, something went wrong.'
        );

      const { url } = result;

      // Cancel any in-progress upload
      if (currentUpload) currentUpload.abort();

      // Create new abort controller for this upload
      const abortController = new AbortController();
      setCurrentUpload(abortController);

      // Create a promise that wraps XMLHttpRequest for upload progress. We have
      // to use XHR instead of the fetch API because it supports progress
      // events.
      const request = new Promise<number>((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        // Watch for progress
        xhr.upload.addEventListener(
          'progress',
          (event) =>
            event.lengthComputable &&
            setUploadProgress(event.loaded / event.total)
        );

        // Resolve the promise on success
        xhr.addEventListener('load', () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.status);
          } else {
            reject(
              new FileUploadFailedError(`HTTP ${xhr.status}: ${xhr.statusText}`)
            );
          }
        });

        // Error and abort handling
        xhr.addEventListener('error', () =>
          reject(new FileUploadFailedError('Network error'))
        );
        xhr.addEventListener('abort', async () => {
          reject(new FileUploadCancelledError());
        });

        // Start the upload
        xhr.open('PUT', url);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.send(file);

        abortController.signal.addEventListener('abort', () => xhr.abort());
      });

      if (form) {
        addUploadToForm(form.id, request);
      }

      const response = await request;
      if (response >= 400) {
        throw new FileUploadFailedError(
          `Upload failed with status ${response}`
        );
      }

      // Update the file record to indicate the upload is complete
      const record = await updateFile({
        id,
        status: FileUploadStatus.UPLOADED,
        mimeType: file.type,
        bytes: file.size,
      });
      if ('error' in record)
        throw new FileUploadFailedError(
          (record.error &&
            ('fields' in record.error
              ? record.error.fields[props.name]?.message
              : record.error.form.message)) ??
            'Whoops, something went wrong.'
        );

      // Clean up
      setUploaded(true);
      setFileRecord(record);
      if (form) {
        removeUploadFromForm(form.id, request);
      }
      props.onFileChange?.(record);
    } catch (error) {
      if (error instanceof FileUploadCancelledError) {
        console.log('Upload cancelled');
      } else if (error instanceof FileUploadFailedError) {
        setError(error.message);
      } else {
        throw error;
      }
    } finally {
      setCurrentUpload(null);
    }
  };

  return (
    <div
      className={cn(
        'group flex flex-auto relative cursor-pointer',
        props.className
      )}
    >
      {props.children?.({
        isUploading: Boolean(currentUpload),
        progress: uploadProgress,
        fileRecord,
      })}
      <input
        type="file"
        form="" // Ensures the input isn't tied to the parent form
        onChange={handleFileChange}
        className="absolute inset-0 opacity-0 cursor-pointer"
        accept={props.accept}
      />
      {uploaded && <input type="hidden" name={props.name} value={id} />}
    </div>
  );
}

export function FileInputDropZone(props: {
  isUploading: boolean;
  progress: number;
  fileRecord: File | null;
  children?: (props: { fileRecord: File | null }) => ReactNode;
}) {
  const children =
    props.children?.({ fileRecord: props.fileRecord }) ??
    (props.fileRecord ? (
      <Image
        src={urlForFile(props.fileRecord)}
        alt=""
        fill
        className="object-contain object-center"
      />
    ) : null);
  return (
    <div className="border border-contrast-200 rounded stack flex-auto items-center justify-center p-4 shadow-sm relative overflow-hidden">
      <div className="absolute inset-0 size-full">{children}</div>
      <UploadCloud className="text-contrast-300 group-hover:text-contrast-500 transition size-1/4" />
      <div className="text-muted text-xs group-hover:text-contrast-700 transition shrink text-center text-balance">
        Drop a file, or click to browse
      </div>
      <AnimatePresence>
        {props.isUploading && (
          <motion.div
            className="absolute inset-0 size-full stack-1 items-center justify-center p-4 bg-contrast-100/80 backdrop-blur"
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
          >
            <div className="text-sm leading-none text-muted">Uploading ...</div>
            <Progress value={props.progress * 100} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export function FormFileInput(
  props: ComponentPropsWithoutRef<typeof FileInput>
) {
  const [error] = useFieldError(props.name ?? '');
  const children = props.children ?? FileInputDropZone;
  return (
    <>
      <FileInput {...props}>{children}</FileInput>
      <AnimatePresence>
        {error && (
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 20, opacity: 0 }}
            className="row justify-end w-full absolute left-0 bottom-full mb-1 z-10"
          >
            <div className="bg-amber-300 row-1 items-center text-contrast-800 px-1.5 pt-0.5 pb-0.5 rounded inline-flex leading-tight text-xs text-right text-balance max-w-full">
              <AlertTriangle size={10} />{' '}
              {typeof error === 'string' ? error : JSON.stringify(error)}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

class FileUploadCancelledError extends Error {}
class FileUploadFailedError extends Error {}
